.flag-icon-background {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
}

.flag-icon {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  position: relative;
  display: inline-block;
  width: 2rem;
  line-height: 1rem;
}

.flag-icon:before {
  content: "\00a0";
}

.flag-icon-de-DE {
  background-image: url('../images/flag/de.svg');
}

.flag-icon-en-EN {
  background-image: url('../images/flag/en.svg');
}

.flag-icon-ru-RU {
  background-image: url('../images/flag/ru.svg');
}

.flag-icon-uk-UA {
  background-image: url('../images/flag/ua.svg');
}