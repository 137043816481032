@font-face {
  font-family: TunerTools;
  src: url("../fonts/Tuner.Tools-Light.woff2") format("woff2"), url("../fonts/Tuner.Tools-Light.woff") format("woff");
  font-display: swap;
  font-weight: 300
}

@font-face {
  font-family: TunerTools;
  src: url("../fonts/Tuner.Tools-Regular.woff2") format("woff2"), url("../fonts/Tuner.Tools-Regular.woff") format("woff");
  font-display: swap;
  font-weight: 400
}

@font-face {
  font-family: TunerTools;
  src: url("../fonts/Tuner.Tools-Bold.woff2") format("woff2"), url("../fonts/Tuner.Tools-Bold.woff") format("woff");
  font-display: swap;
  font-weight: 700
}