// Typography //////////////////////////////////////////////////////////////////////////////////////////////////////////

// Body
$site-font-family: TunerTools, 'Segoe UI', sans-serif;
$site-font-size: 1.0rem;
$site-font-weight: 300;
$site-line-height: 1.5;
$site-letter-spacing: 0.01rem;

// Header
$site-logo-font-family: $site-font-family;

// Headline
$site-headline-font-weight: 400;

// Code
$site-code-font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;

// Colors //////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Brands
$brand-color-1: rgba(247, 247, 247, 100); // #f7f7f7; // rgba(138, 171, 185, 152); // rgba(255, 255, 255, 0.6);
$brand-color-2: rgba(255, 170, 0, 100); // #FA0; // rgb(246, 121, 0); // #F67900
$brand-color-3: rgba(248, 93, 0, 100); // #f85d00;
$brand-color-4: rgba(41, 51, 55, 45); // #454545;
$brand-color-5: rgba(0, 0, 0, 100); // #000000;

// Body
$site-bg-color: $brand-color-1;
$site-color: $brand-color-4;

// Header
$site-header-primary-color: $brand-color-1;
$site-header-secondary-color: $brand-color-2;

// Navbar
$navbar-bg-color: $brand-color-5;

// Headline
$site-headline-color: $brand-color-4;
$site-headline-border-gradient: linear-gradient(45deg, $brand-color-2, $brand-color-3);

// Hr
$site-hr-color: $brand-color-3;

// Borders
$site-border-color: lighten($brand-color-2, 10%);

// Buttons
$site-btn-bg-color: $brand-color-3;
$site-btn-color: $site-color;
$site-btn-color-hover: $brand-color-5;

// Footer
$site-footer-tagline-color: $brand-color-4;