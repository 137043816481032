// Header //////////////////////////////////////////////////////////////////////////////////////////////////////////////

.site-name {
  font-family: $site-logo-font-family;
  font-size: 1.3rem;
  font-weight: 400;
  //text-shadow: 0 0 8px $site-header-brand-secondary-color;
  letter-spacing: 0.3rem;
  -webkit-transform: scale(0.55, 1.0);
  -moz-transform: scale(0.55, 1.0);
  -ms-transform: scale(0.55, 1.0);
  -o-transform: scale(0.55, 1.0);
  transform: scale(0.55, 1.0);
}

.vertical-hr {
  background-color: $site-btn-bg-color;
}

.site-brand-text-primary-color {
  color: $site-header-primary-color;
}

.site-brand-text-secondary-color {
  color: $site-header-secondary-color;
}

.navbar-brand {
  margin-right: 0;
}

.bg-dark {
  background-color: $navbar-bg-color !important;
}

a.bg-dark:hover,
a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: $site-btn-bg-color !important;
}