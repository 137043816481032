// Body ////////////////////////////////////////////////////////////////////////////////////////////////////////////////

body {
  margin: 0;
  font-family: $site-font-family;
  font-weight: $site-font-weight;
  font-size: $site-font-size;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $site-color;
  background: $site-bg-color;
  line-height: $site-line-height;
  letter-spacing: $site-letter-spacing;
}

code {
  font-family: $site-code-font-family;
}

// Headline ////////////////////////////////////////////////////////////////////////////////////////////////////////////

h1, h2, h3, h4, h5, h6 {
  font-weight: $site-headline-font-weight;
  color: $site-headline-color;
}

h1 {
  font-size: 1.3em;
}

h2 {
  font-size: 1.2em;
}

h3 {
  font-size: 1.1em;
}

.headline-container {
  display: flex;
  color: $site-hr-color;
}

.headline-container:before,
.headline-container:after {
  content: '';
  margin: auto 1rem;
  border-bottom: solid 0.1rem;
  flex: 1;
}

.headline-div {
  background: $site-headline-border-gradient;
  border-radius: 50%;
  padding: 0.1rem;
}

.headline-div span {
  display: inline-block;
  width: 2.5rem;
  height: 2.5rem;
  background: $site-bg-color;
  text-align: center;
  padding: 6px;
  border-radius: 50%;
}

#icon-gradient {
  --color-stop-2: rgba(255, 170, 0, 100); // $brand-color-2;
  --color-stop-3: rgba(248, 93, 0, 100); // $brand-color-3;
}

.headline-icon * {
  fill: url(#icon-gradient) $site-hr-color;
}

// Hr //////////////////////////////////////////////////////////////////////////////////////////////////////////////////

hr {
  color: $site-hr-color;
}

// Image ///////////////////////////////////////////////////////////////////////////////////////////////////////////////


// Button //////////////////////////////////////////////////////////////////////////////////////////////////////////////

.engine-select-button button {
  min-width: 80%;

  //background-color: $site-btn-bg-color;
  //color: $site-btn-color;

  &:hover {
    //background-color: $site-btn-color-hover;
  }
}

.engine-select-button .dropdown-menu {
  min-width: 78%;
}

.btn-link:focus,
.btn-link:active:focus {
  outline: none;
}

.btn-link:focus {
  text-decoration: none;
}

.btn-link:hover {
  text-decoration: underline;
}

// Link ////////////////////////////////////////////////////////////////////////////////////////////////////////////////

a {
  color: $site-btn-color;
}

a:hover {
  color: $site-btn-color-hover;
}

.a-link {
  text-decoration: none;
}

.a-link:hover {
  text-decoration: underline;
}

// Checkbox ////////////////////////////////////////////////////////////////////////////////////////////////////////////

.checkbox-lg {
  width: 24px;
  height: 24px;
}

// Radiobutton /////////////////////////////////////////////////////////////////////////////////////////////////////////

.radio-lg {
  width: 24px;
  height: 24px;
}

// Spacing /////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Text ////////////////////////////////////////////////////////////////////////////////////////////////////////////////

